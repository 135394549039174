import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';
import { ActivatedRoute } from '@angular/router';
import { CepService } from 'src/app/ceps/shared/cep.service';
import { Cd } from './cd';

@Injectable({
  providedIn: 'root'
})
export class AlterarLojaService {
  private _depoisSelecionarLoja$: Subject<any> = new Subject();
  public depoisSelecionarLoja$: Observable<
    any
  > = this._depoisSelecionarLoja$.asObservable();

  retiradas: Cd[] = [];
  cdSelecionadoViaParametro = false;

  constructor(
    private lsService: LocalStorageService,
    private httpClient: HttpClient,
    private route: ActivatedRoute,
    private cepService: CepService) {}

  public getOrigem(): Cd {
    return this.lsService.get('loja');
  }

  private salvarLojaLocal(loja: Cd) {
    this.lsService.set('loja', loja);
    this.lsService.set('cdSelecionado', loja.id);
  }

  public alterarLoja(lojaDestino: Cd) {
    const lojaOrigem = this.getOrigem();
    const lojaAlterada = lojaOrigem.id !== lojaDestino.id;
    this.salvarLojaLocal(lojaDestino);

    return {
      lojaAlterada,
      lojaOrigem,
      lojaDestino
    };
  }

  public salvarLojaSelecionada(lojaDestino: Cd, montarCarrinho: boolean = true) {
    const infoEmit = this.alterarLoja(lojaDestino);
    this._depoisSelecionarLoja$.next({ ...infoEmit, montarCarrinho });
  }

  public confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
    return confirm(msg);
  }

  verificarPossibilidadeTrocarCarrinhoCd(cdAtualId: number, novoCdId: number) {
    const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdAtualId}/verificar_possibilidade_trocar_carrinho_cd`;
    return this.httpClient.post<any>(`${environment.apiUrl}/loja/${URI}`, { novo_cd_id: novoCdId });
  }

  selecionarCdViaParametro() {
    this.route.queryParams.subscribe(params => {
      const cdNovoId = params['cd'];
      if (cdNovoId) {
        this.cepService.buscaRetiradas().subscribe((req: any) => {
          this.retiradas = req.data;
          const cdNovo = this.retiradas.find((cd) => cd.id === Number(cdNovoId));
          const cdAtual = this.getOrigem();
          if (cdAtual && cdNovo && cdAtual.id !== cdNovo.id) {
            this.verificarPossibilidadeTrocarCarrinhoCd(cdAtual.id, cdNovo.id)
            .subscribe(
              () => {
                this.cdSelecionadoViaParametro = true;
                this.salvarLojaSelecionada(cdNovo, false);
              },
              err => {
                if ('error' in err) {
                  window.alert(err.error.error.message);
                }
              }
            );
          }
        });
      }
    });

   

  }
}
