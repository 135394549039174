import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MensagemAlteracaoCd } from './MensagemAlteracaoCd';
import { ActivatedRoute } from '@angular/router';
import { CepService } from 'src/app/ceps/shared/cep.service';
import * as i0 from "@angular/core";
import * as i1 from "angular-2-local-storage";
import * as i2 from "@angular/common/http";
import * as i3 from "@angular/router";
import * as i4 from "../../ceps/shared/cep.service";
export class AlterarLojaService {
    constructor(lsService, httpClient, route, cepService) {
        this.lsService = lsService;
        this.httpClient = httpClient;
        this.route = route;
        this.cepService = cepService;
        this._depoisSelecionarLoja$ = new Subject();
        this.depoisSelecionarLoja$ = this._depoisSelecionarLoja$.asObservable();
        this.retiradas = [];
        this.cdSelecionadoViaParametro = false;
    }
    getOrigem() {
        return this.lsService.get('loja');
    }
    salvarLojaLocal(loja) {
        this.lsService.set('loja', loja);
        this.lsService.set('cdSelecionado', loja.id);
    }
    alterarLoja(lojaDestino) {
        const lojaOrigem = this.getOrigem();
        const lojaAlterada = lojaOrigem.id !== lojaDestino.id;
        this.salvarLojaLocal(lojaDestino);
        return {
            lojaAlterada,
            lojaOrigem,
            lojaDestino
        };
    }
    salvarLojaSelecionada(lojaDestino, montarCarrinho = true) {
        const infoEmit = this.alterarLoja(lojaDestino);
        this._depoisSelecionarLoja$.next(Object.assign({}, infoEmit, { montarCarrinho }));
    }
    confirmarAlteracaoCd(msg = MensagemAlteracaoCd.MENSAGEM_PADRAO) {
        return confirm(msg);
    }
    verificarPossibilidadeTrocarCarrinhoCd(cdAtualId, novoCdId) {
        const URI = `carrinhos/filial/${environment.filialId}/centro_distribuicao/${cdAtualId}/verificar_possibilidade_trocar_carrinho_cd`;
        return this.httpClient.post(`${environment.apiUrl}/loja/${URI}`, { novo_cd_id: novoCdId });
    }
    selecionarCdViaParametro() {
        this.route.queryParams.subscribe(params => {
            const cdNovoId = params['cd'];
            if (cdNovoId) {
                this.cepService.buscaRetiradas().subscribe((req) => {
                    this.retiradas = req.data;
                    const cdNovo = this.retiradas.find((cd) => cd.id === Number(cdNovoId));
                    const cdAtual = this.getOrigem();
                    if (cdAtual && cdNovo && cdAtual.id !== cdNovo.id) {
                        this.verificarPossibilidadeTrocarCarrinhoCd(cdAtual.id, cdNovo.id)
                            .subscribe(() => {
                            this.cdSelecionadoViaParametro = true;
                            this.salvarLojaSelecionada(cdNovo, false);
                        }, err => {
                            if ('error' in err) {
                                window.alert(err.error.error.message);
                            }
                        });
                    }
                });
            }
        });
    }
}
AlterarLojaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AlterarLojaService_Factory() { return new AlterarLojaService(i0.ɵɵinject(i1.LocalStorageService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.ActivatedRoute), i0.ɵɵinject(i4.CepService)); }, token: AlterarLojaService, providedIn: "root" });
